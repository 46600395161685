import { IDailyClosingReq, IDailyReceiptReq } from '@interfaces/network';
import { apiService } from '@services/api';

const getDailyClosingReport = async (request: IDailyClosingReq) =>
  await apiService.get(`/reports/daily-closing`, {
    params: request,
  });

const getDailyReceiptReport = async (request: IDailyReceiptReq) =>
  await apiService.get(`/reports/daily-receipt`, {
    params: request,
  });

export { getDailyClosingReport, getDailyReceiptReport };
