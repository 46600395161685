import { Header, Navigation } from '@components/layouts';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { IRoute } from './index';
import { Layout, Card } from 'antd';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';

import { transactionIdVar } from '@services/graphql/core';

const Main = styled(Layout.Content)`
  margin-top: 60px;
  padding: 20px;
  background-color: #fafbfd;
  min-height: 400px;
`;

interface PrivateRouteProps {
  isLoggedIn: boolean;
  history?: any;
  title: string;
  children: ReactNode;
  privateRoutes: IRoute[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  isLoggedIn,
  history,
  title,
  children,
  privateRoutes,
  ...props
}) => {
  const [displayNotification, setDisplayNotification] = useState(true);
  const [collapsed, setCollapse] = useState(
    !!('ontouchstart' in window || navigator?.msMaxTouchPoints),
  );
  const { pathname, search } = useLocation();

  const _handleToggleCollapse = () => setCollapse(!collapsed);

  if (search.indexOf('transaction_id') > -1) {
    transactionIdVar(search);
  }

  const renderChildren = () => {
    if (pathname === '/' && isLoggedIn) {
      return <Redirect to="/classes" />;
    }

    if (history?.location?.pathname === '/' && !isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <Route
        {...props}
        render={({ location, match }) => {
          const crumbs = privateRoutes
            .filter(({ path }) => match.path.includes(path))
            .map(({ path, ...rest }) => ({
              path: Object.keys(match.params).length
                ? Object.keys(match.params).reduce(
                    (path, param) =>
                      path.replace(`:${param}`, match.params[param] as string),
                    path,
                  )
                : path,
              ...rest,
            }));

          return isLoggedIn ? (
            <>
              <Header
                title={title}
                breadcrumbs={crumbs}
                isCollapse={collapsed}
                toggleCollapse={_handleToggleCollapse}
              />
              {displayNotification && (
                <Card className="global-notification">
                  <div className="text">
                    Dear Students, Do note that ALL Royal Square classes are now
                    shifted over to our NEW branch at United Square (Office
                    Tower) #17-01. See you at the new centre!
                  </div>
                  <Icon
                    onClick={() => setDisplayNotification(false)}
                    className="icon"
                    viewBox={'0, 0, 1024, 1024'}
                  >
                    <FontAwesomeIcon icon={['far', 'times-circle']} />
                  </Icon>
                </Card>
              )}
              {children}
            </>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout>
        <Navigation
          collapsed={collapsed}
          toggleCollapse={_handleToggleCollapse}
        />
        <Main id="scrollableDiv">{renderChildren()}</Main>
      </Layout>
    </Layout>
  );
};

export default PrivateRoute;
